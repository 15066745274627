import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import { useStores } from "_common/hooks";
import { useQuery } from "@tanstack/react-query";
import { FundApi } from "states/api/fund";

function FundPage() {
  const {
    authStore: { games },
  } = useStores();

  const { data, refetch } = useQuery(
    ["fund", "list"],
    () =>
      FundApi.getList({
        params: {
          page: 1,
          limit: 20,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <>
      <HeaderTitle title="Gói đầu tư " />
      <div className="page-container">
        <div className="level-page">
          {data?.data?.map((item) => (
            <Link to={item?.url} alt="" key={item?.id}>
              <img src={item?.avatar_url} alt="" />
              <h3>{item?.name}</h3>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
}

export default FundPage;
