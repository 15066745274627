import classNames from "classnames";
import React, { useEffect, useState } from "react";

import { Link, useLocation } from "react-router-dom";
import { useStores } from "_common/hooks";

import DepositIcon from "assets/icons/DepositIcon";
import HomeIcon from "assets/icons/HomeIcon";
import SupportIcon from "assets/icons/SupportIcon";
import UserIcon from "assets/icons/UserIcon";

export default function MobileFooter({ toggleMenu, toggleCollapsed, menus }) {
  const {
    authStore: { user, user_wallet },
  } = useStores();
  const location = useLocation();
  const pathName = location?.pathname;

  return (
    <div className="footer-mobile">
      <Link
        to="/"
        className={classNames("footer_item", {
          active: pathName === "/",
        })}
      >
        <HomeIcon />
        <h5>Trang chủ</h5>
      </Link>
      <Link
        to="/fund"
        className={classNames("footer_item", {
          active: pathName === "/fund",
        })}
      >
        <DepositIcon />
        <h5>Hạng Mục</h5>
      </Link>
      <Link
        to="/payment/withdraw"
        className={classNames("footer_item", {
          active: pathName === "/payment/withdraw",
        })}
      >
        <DepositIcon />
        <h5>Rút tiền</h5>
      </Link>
      <Link
        to="/support"
        className={classNames("footer_item", {
          active: pathName === "/support",
        })}
      >
        <SupportIcon />
        <h5>CSKH</h5>
      </Link>
      <Link
        to="/member"
        className={classNames("footer_item", {
          active: pathName === "/member",
        })}
      >
        <UserIcon />
        <h5>Cá nhân</h5>
      </Link>
    </div>
  );
}
