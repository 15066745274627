import React, { useEffect, useState } from "react";
import { Portal } from "react-portal";
import box from "assets/img/bonus.png";
import { useStores } from "_common/hooks";
import { observer } from "mobx-react-lite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const NoticeBonus = observer(({ content, onClose }) => {
  const navigate = useNavigate();
  const {
    authStore: { user },
  } = useStores();

  return (
    <Portal>
      <div className="modal modal-gift">
        <div className="mask" />
        <div className="modal-root ">
          <div className="modal-header">
            <h2>Thông báo</h2>
          </div>
          <div className="modal-content">
            <FontAwesomeIcon className="info-icon" icon={faInfoCircle} />
            <p className="bonus">{content?.note}</p>

            {content?.block ? (
              <div className="btn-group">
                <button
                  type="button"
                  className="btn btn-buy"
                  onClick={() => navigate("/support")}
                >
                  Liên hệ CSKH
                </button>
              </div>
            ) : (
              <div className="btn-group">
                <button
                  type="button"
                  className="btn btn-buy"
                  onClick={() => onClose()}
                >
                  Xác nhận
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </Portal>
  );
});

export default NoticeBonus;
