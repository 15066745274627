import { useQueryClient } from "@tanstack/react-query";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Outlet, useSearchParams } from "react-router-dom";
import { useStores } from "_common/hooks";
import { useNotify } from "_common/component/NotifyProvider";
import NoticeBonus from "_common/component/NoticeBonus";

import MobileFooter from "../Footer/MobileFooter";

export default function MobileLayout() {
  const {
    authStore: { user },
  } = useStores();
  const { notify, setNotify } = useNotify();
  const [openBonus, setOpenBonus] = useState();
  const [msgData, setMsgData] = useState();

  useEffect(() => {
    if (
      notify?.data?.type === "bonus" &&
      (notify?.data.user === user?.id || notify?.data.user === user?.username)
    ) {
      setOpenBonus(true);
    }
    if (user?.status === 2) {
      setOpenBonus(true);
      setMsgData({
        note: "Tài khoản của quý khách đã bị đóng băng, vui lòng liên hệ CSKH để biết thêm chi tiết !",
        block: true,
      });
    }

    if (user?.status === 3) {
      setOpenBonus(true);
      setMsgData({
        note: "Hệ thống đang tạm thời bảo trì nâng cấp, vui lòng liên hệ CSKH để biết thêm chi tiết ! ",
        block: true,
      });
    }

    if (user?.status === 1) {
      setOpenBonus(false);
      setMsgData();
    }
  }, [notify, user]);

  return (
    <div className="mobile-layout">
      <div className="tab-layout">
        <Outlet />
        <MobileFooter />
      </div>
      {openBonus && (
        <NoticeBonus
          content={msgData || notify?.data}
          onClose={() => {
            window.location.reload();
            setOpenBonus(false);
          }}
        />
      )}
    </div>
  );
}
